/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const DarkBox = () => {
  return (
    <React.Fragment>
      <div sx={{ display: `block`, height: `30px`, margin: `0`, backgroundColor: `#2a2d34` }}>
        {` `}
      </div>
    </React.Fragment>
  )
}

export default DarkBox