/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Box } from "@theme-ui/components"
import Layout from "./layout"
import Listing from "./listing"
import ButtonDark from "./button-dark"
import PageHeader from "./header-page"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import SEO from "./seo"

type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
}

const Blog = ({ posts }: PostsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig()

  return (
    <Box>
    <PageHeader text="All posts" bgImg="book-compact" />
    <Layout>
      <SEO title="Blog" />
      <Listing posts={posts} />
      <Flex sx={{ display: `block`, textAlign: `center`, margin: `0 auto`, mb: 5}}>
        <ButtonDark text="Posts by tags" targetUrl={replaceSlashes(`/${basePath}/${tagsPath}`)} />
      </Flex>
    </Layout>
    </Box>
  )
}

export default Blog
